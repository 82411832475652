<template>
  <div>

    <template v-if="protocol == 'SAML2'">

      <v-text-field
          v-model="authDatas.loginUrl"
          @input="emitInput"
          label="SSO Login URL"
      ></v-text-field>

      <v-text-field
          v-model="authDatas.logoutUrl"
          @input="emitInput"
          label="SSO Logout URL"
      ></v-text-field>

      <v-textarea
          v-model="authDatas.certificates"
          @input="emitInput"
          label="Certificate x.509"
      ></v-textarea>
    </template>
    <template v-else>
      <div class="d-flex flex-column align-center">Le protocol sélectionné n'est pas implémenté</div>
    </template>
  </div>
</template>

<script>
export default {
  name: "AuthDatasForm",
  props: {
    value: Object,
    protocol: String
  },
  data() {
    return {
      authDatas: {}
    }
  },
  methods: {
    reset() {
      this.authDatas = {}
    },
    switchProtocol(protocol) {
      this.reset()
      if (protocol == "SAML2") {
        this.authDatas = {
          loginUrl: "",
          logoutUrl: "",
          certificate: {
            type: "", // Pouvoir renseigner un fichier, une url ou un string
            value: ""
          }
        }
      }
    },
    emitInput() {
      this.$emit('input', this.authDatas)
    }
  },
  watch: {
    protocol(newProtocol) {
      this.switchProtocol(newProtocol)
    },
    value(newValue) {
      this.authDatas = newValue
    }
  },
  mounted() {
    this.authDatas = this.value
  }

}
</script>

<style scoped>

</style>